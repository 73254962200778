<template>
  <div>
    <!--上半部-->
    <div class="mainPerson1">
      <!--头像-->
      <div class="avatar">
        <el-avatar :size="150" :src="avatarUrl" fit="fill"></el-avatar>
      </div>
      <!--个人资料-->
      <div class="personInfo">
        <span><b>{{$t('MyAccount')}}:</b>&nbsp {{account}}</span>
        <div>
          <span><b>{{$t('Name')}}:</b>&nbsp {{adminName}}<i class="el-icon-edit" @click="changeText(adminName)"></i></span>
          <span><b>{{$t('ContactNum')}}:</b>&nbsp {{mobile}}</span>
        </div>
        <el-button @click="logout">{{$t('Logout')}}</el-button>
      </div>
    </div>


    <!--下半部-->
    <div class="mainPerson2">
      <span><b>{{$t('SystemManagement')}}:</b>{{numToAuthority(systemPermission)}}</span>
      <span><b>{{$t('UserManagement')}}:</b>{{numToAuthority(userPermission)}}</span>
      <span><b>{{$t('ContentManagement')}}:</b>{{numToAuthority(contentPermission)}}</span>
      <span><b>{{$t('MessageManagement')}}:</b>{{numToAuthority(messagePermission)}}</span>
      <span><b>{{$t('AdminManagement')}}:</b>{{numToAuthority(administratorPermission)}}</span>
    </div>

    <!--编辑信息dialog-->
    <el-dialog :close-on-click-modal="false" :title="$t('EditPerson')" :visible.sync="editAdminDialog" append-to-body>
      <el-form :model="dialogContentForm">
        <el-form-item :label="$t('Name')" label-width="120px">
          <!--<el-form-item label="商会名称" label-width="120px">-->
          <el-input v-model="dialogContentForm.dialogContent" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editAdminDialog = false">{{$t('Cancle')}}</el-button>
        <el-button type="primary" @click="editName">{{$t('Confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
    import api from '@/fetch/api'
    import mixin from '@/assets/js/mixin'
    export default {
        mixins:[mixin],
        name:'',
        data () {
            const data = JSON.parse(localStorage.getItem('cacheChamber')).adminData
            return {
                adminId:data.id,
                account:data.username,
                adminName:data.name,
                avatarUrl: data.avatarUrl?data.avatarUrl:"https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
                mobile:data.mobile,
                editAdminDialog:false,
                systemPermission:data.systemPermission,
                userPermission:data.userPermission,
                contentPermission:data.contentPermission,
                messagePermission:data.messagePermission,
                administratorPermission:data.administratorPermission,
                dialogContentForm:{
                    changingOne:0,
                    dialogContent :'',
                    label:'',
                }
            }
        },

        mounted() {
            console.log('personalCenter')
        },

        updated() {
            console.log('personalCenter')
        },
        methods: {
            changeText(name) {
//                if (confirm(this.$t('alert'))) {
                this.editAdminDialog = true;
                this.dialogContentForm.dialogContent = name;

//                }
            },
            editName(){
                api.editAdmin({
                    adminId:this.adminId,
                    name:this.dialogContentForm.dialogContent,
                    id:this.adminId
                }).then(res=>{
                    // console.log('更改名称',res)
                    this.adminName = this.dialogContentForm.dialogContent;
                    this.editAdminDialog = false
                })
            },
//            退出登录
            logout(){
                this.$store.commit('menu/clear')
                this.$router.push({path: "/"})
            }
        },
    }
</script>

<style lang="scss" scoped>
  .mainPerson1{
    width: 1600px;
    height:250px;
    /*border:1px solid black;*/
    background-color: #425D7B;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 20% 80%;
    .avatar{
      margin: auto;
    }
    .personInfo{
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: flex-start;
      font-size: 20px;
      line-height: 40px;
      color: white;
      span{
        margin-right: 50px;
      }
      i{
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  .mainPerson2{
    width: 1600px;
    height:300px;
    margin-top: 20px;
    padding:50px 100px;
    background-color: #425D7B;
    border-radius: 5px;
    display: grid;
    grid-template-rows: repeat(3,1fr);
    grid-template-columns: 40% 60%;
    color: white;
    b{
      margin-right: 20px;
    }
  }

</style>